@font-face {
    font-family: 'Manrope';
    src: local('Manrope-Regular'), url(./assets/font/Manrope-Regular.ttf) format('truetype');
}

@font-face {
    font-family: 'Manrope';
    font-weight: 900;
    src: local('Manrope-Bold'), url(./assets/font/Manrope-Bold.ttf) format('truetype');
}
  
@font-face {
    font-family: 'Manrope';
    font-weight: 700;
    src: local('Manrope-SemiBold'), url(./assets/font/Manrope-SemiBold.ttf) format('truetype');
}

body {
    margin: 0;
    padding: 0;
    font-family: "Manrope";
}

/* Extra small devices (phones, 320px and down) */
@media only screen and (max-width: 320px) {
    html {
        font-size: 50%; /* this means, font-size: 10px : */
    }
}

/* Medium and Large phones  */
@media only screen and (min-width: 321px) and (max-width: 425px) {
    html {
        font-size: 62.5%; /* this means, font-size: 10px : */
    }
}

/* Extra large phones (phones, 600px and down) */
@media only screen and (min-width: 426px) and (max-width: 600px) {
    html {
        font-size: 70%; /* this means, font-size: 10px : */
    }
}

/* Tablets and large phones, 600px and up) */
@media only screen and (min-width: 601px) {
    html {
        font-size: 75%; 
    }
}

/* Large devices (laptops/desktops, 992px and up) */
@media only screen and (min-width: 992px) {
    html {
        font-size: 90%; 
    }
}

/* Extra large devices (large laptops and desktops, 1200px and up) */
@media only screen and (min-width: 1200px) {
    html {
        font-size: 100%; 
    }
}
